body {
  overflow: hidden;
}
#wrapper {
  height: 100%;
}
.top-header {
  background: #f4f4f7;
  border-bottom: 1px solid #e8e8e8;
  padding: .5rem 1rem;
  flex-shrink: 0;
}

#upload-link-panel {
  width: 928px;
  max-width: calc(100% - 20px);
  border: 1px solid #ddd;
  margin: 2em auto;
}
.shared-by .avatar {
  width: 20px;
  height: 20px;
}
#upload-link-panel .warning-icon {
  color: #f25041;
  font-size: 48px;
}
#upload-link-panel .tip-list-item {
  list-style: decimal inside none;
}
#upload-link-drop-zone {
  background: rgba(240, 159, 63, 0.1);
  border: 2px dashed #f09f3f;
  border-radius: 4px;
  padding: 28px 0;
}
#upload-link-drop-zone .upload-icon {
  color: rgba(240, 159, 63, 0.8);
  font-size: 60px;
  line-height: 1;
}
.mh-2 {
  min-height: 2rem;
}
